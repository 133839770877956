@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-layouts/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import "../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-kanban/styles/material.css";


.margin {
    margin: 10px 20px 00px 0px;
}
.marginToolbar {
    margin: 10px;
}