.grid-container-element {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 50%;
}

.grid-child-element {
    margin: 10px;
}
.checkBox {
    margin: 12px;
}