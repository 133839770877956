
.list-container {
    height: 100%;
    margin: 0px;
}

.item {
    height: 65px;
    font-size: 14px;
    font-weight: bold;
}

.item > div {
 
}

.item > div.title {
    font-size: 12px;
    font-weight:400;
    margin-top: 5px;
}

.date {
    font-size: 12px;
}

.dx-list-item-content {
    font-size: 16px;
    padding: 10px 10px 5px;
}

.red-dot {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%; /* To make it round */
    display: inline-block; /* To prevent it from taking the full line */
}

/* Optional: To add some space between the dot and adjacent content */
.red-dot::before {
    content: ' ';
    display: inline-block;
    width: 10px; /* Adjust as needed */
}
