
#tasks-grid {
    min-height: 300px;
}

.dx-datagrid-header-panel {
    padding-top: 0;
}

.dx-datagrid-header-panel .dx-datagrid-toolbar-button .dx-icon.dx-icon-plus,
.dx-datagrid-header-panel .dx-datagrid-toolbar-button .dx-button-text {
    color: #fff;
}

.edit-cell {
    position: relative;

    .priority-task,
    .status-task {
        position: absolute;
        margin-top: 10px;
        margin-left: 11px;
    }
}

@mixin item-field($status-color) {
    color: $status-color;

    &.dx-texteditor {
        .item-editor-input {
            color: $status-color;
            padding-left: 0;
        }
    }
}

.item-editor-field .item-field,
.item-field {
    &.item-open {
        @include item-field(#505ed9);
    }

    &.item-inprogress {
        @include item-field(#34aa95);
    }

    &.item-cancelled {
        @include item-field(#969696);
    }

    &.item-completed {
        @include item-field(#2b9029);
    }

    &.item-low {
        @include item-field(#d3a268);
    }

    &.item-normal {
        @include item-field(#6fbaca);
    }

    &.item-high {
        @include item-field(#ff5722);
    }
}